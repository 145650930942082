
$('.welfare-link-btn').attr('href')// window.onload = function() {
//     // 页面初始化
//     obj.init();
// }

var u = navigator.userAgent;
var config = { account: "", channel: "" };

var bindParams = {};

// var api = "https://tapi.qingcigame.com";    // 测试链接
var api = "https://api.qingcigame.com";
var shareURL = "https://snail.qingcigame.com/leading";
var game_id = 47;
// var game_id = 40;
var num = 0; //当前播放歌曲的下标
var isMouseMoved = false
var music = new Howl({
  src: ["//download4.qcplay.com/qscy/video/BGM_shop.mp3"],
  autoplay: true,
  loop: true,
  onplay: function () {
    $('.h-music-box').addClass('music-on'); //开始播放样式
  }
});
var musicCount = 1;


var obj = {
  init: function () {
    this.flexible() // 自适应
    this.musicPlay();
    this.preloading(); // 预加载
    this.indexAjax(); // 接口请求
    this.swiper();
    this.indexBind(); // 所有绑定事件
    this.share();
  },
  news: function () {
    this.flexible() // 自适应
    this.musicPlay();
    this.indexBind(); // 所有绑定事件
    this.partPublicFn()
    this.newsBind(); // 接口请求
    this.scroll();
    this.share()
  },
  newsDetails: function () {
    this.flexible() // 自适应
    this.musicPlay();
    this.indexBind(); // 所有绑定事件
    this.detailBind(); // 接口请求
    this.scroll();
    this.partPublicFn()
    this.share()
  },
  // 自适应
  flexible: function () {
    $(function () {
      function t() {
        var t = $("html").width()
        if (t <= 1400) {
          $("html").css({ "font-size": (35 / 1400) * t + "px" })
        } else if (t <= 1920) {
          $("html").css({ "font-size": "35px" })
        } else if (t <= 2560) {
          $("html").css({ "font-size": (t - 1920) / 32 + 35 + "px" })
        }
      }
      $(window).resize(t), t()
    })
  },

  preloading: function () {
    //图片预加载
    var queue = new createjs.LoadQueue();
    queue.on("progress", function (e) {
      //加载中的进度
      if (e.progress * 100 >= 10) {
        $(".loding_time .dog_run").html('阿努比斯冲刺中' + parseInt(e.progress * 100) + '%')
      };
      var numbers = e.progress * 100;
    }, this);
    queue.on("complete", function (e) {
      //加载完成执行操作
      var _this = this;
      $('.index').addClass('index-show');

      setTimeout(function () {
        $('.preloading').remove();
      }, 1000)
    }, this);
    queue.loadManifest([
      //加载图片，不包括后台的
      '/official/images/pc/01/bg-8877c9465a.png',
      '/official/images/pc/01/Android-6581a4386f.png',
      '/official/images/pc/01/apple-b182886ad8.png',
      '/official/images/pc/01/wx-ab68560367.png',
      '/official/images/pc/01/code-e463b8c7af.png',
      '/official/images/pc/01/service-4fad02518a.png',
      '/official/images/pc/01/play-98f3da95d6.png',
      '/official/images/pc/01/year-98bfcaf154.png',
      '/official/images/pc/01/border_sidle_box-d2280bc654.png',
      '/official/images/pc/01/border_sidle_box_click-aa149cf3c2.png',
      '/official/images/pc/01/border_sidle-463e0bcf00.png',
      '/official/images/pc/01/bottom-ade7bb7908.png',
    ]);

  },
  // 网站音乐
  musicPlay: function () {
    music.play();
    // 点击音乐
    $('.h-music-box').click(function (event) {
      if (musicCount == 1) {
        // $('#audioTag')[0].pause();
        music.pause();
        $(this).removeClass('music-on'); //开始播放样式
        musicCount = 0;
      } else {
        $(this).addClass('music-on'); //开始播放样式
        music.play();
        musicCount = 1;
      }
    });
  },

  // 所有绑定事件
  swiper: function () {
    var index = this.getQueryVariable("index") || 0
    var swiperH = new Swiper('.swiper-container-h', {
      direction: 'vertical',
      resistanceRatio: 0,
      mousewheelControl: true,
      speed: 500,
      slidesPerView: 'auto',
      initialSlide: index,
      sensitivity: 3, // 设置滑动灵敏度


      onInit: function (swiper) { //Swiper2.x的初始化是onFirstInit
        swiperAnimateCache(swiper); //隐藏动画元素
        swiperAnimate(swiper); //初始化完成开始动画
        $('.back').click(function () {
          swiperH.slideTo(0, 500, true);
        })
        $('.float_bg_btn').click(function (e) {
          if ($(this).hasClass("music")) {
            return false
          }
          var data = $(this).attr('data');
          $(this).addClass("active").siblings().removeClass("active")

          swiperH.slideTo(data, 500, true);
        })
      },
      onSlideChangeStart(swiper) {
        swiperAnimate(swiper);
        let index = swiper.realIndex;
        $(' .float_bg_btn' + index + '').addClass("active").siblings().removeClass("active")
      },
      onSlideChangeEnd: function (swiper) {
        swiperAnimate(swiper); //每个slide切换结束时也运行当前slide动画
      },
      onTouchEnd: function (swiper) {
        swiperAnimate(swiper);
      },
    });
  },
  bannerSwiper: function () {
    var mySwiper2 = new Swiper('.swiper-container2', {
      loop: true,
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      initialSlide: 3,
      pagination: '.swiper-pagination',
      prevButton: '.swiper-button-prev3',
      nextButton: '.swiper-button-next3',
      autoplayDisableOnInteraction: false,
      // autoplay: 2000,
      coverflow: {
        rotate: 0, // 旋转的角度
        stretch: 4, // 拉伸   图片间左右的间距和密集度
        depth: 60, // 深度   切换图片间上下的间距和密集度
        modifier: 7.6, // 修正值 该值越大前面的效果越明显
        slideShadows: false
      },
    });
    var swiperB = new Swiper('.swiper-banner', {
      loop: true,
      slidesPerView: "auto",
      effect: 'horizontal',
      centeredSlides: true,
      autoplayDisableOnInteraction: false,
      autoplay: 3000,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      autoplay: false, // 取消自动播放
    })
  },
  indexBind: function () {
    var src = 'https://download4.qcplay.com/file/video_30m.mp4';
    $('.play-btn').attr('data', src);
    var Media = document.getElementById('media-play');
    // 媒体视频播放
    $('.video-btn').on('click', function (event) {
      music.pause();
      musicCount = 1;
      var _this = $(this);
      $(".play-popup ").fadeIn()
      Media.play(); //播放
    });
    $(".play-popup ").on('click', function (event) {
      Media.pause();
      var _this = $(this);
      Media.pause(); //暂停
      $(".play-popup ").fadeOut()
      musicCount = 0;
    })
    $('.bd-bg .hd ul li').click(function (e) {
      $(this).addClass("on").siblings().removeClass("on")
      if (this.classList.value.indexOf('01') !== -1) {
        $('#gong_gao').fadeOut()
        $('#activity_news').fadeIn()
      }
      if (this.classList.value.indexOf('02') !== -1) {
        $('#gong_gao').fadeIn()
        $('#activity_news').fadeOut()
      }
    })

    $('.img_box .image-container').click(function (e) {
      let data = $(this).attr('data');
      if (data) {
        window.location.href = data
      } else {
        layer.msg("敬请期待", { time: 2000 });
      }
    })
    $(".wx").click(function (event) {
      __bl.sum('骑士冲呀官网,关注企微');
      $(".qwechat_pop").fadeIn()
      $(".share-wrap .popup_content").addClass("popup_content-ani");
    });
    $(".code_box").click(function (event) {
      __bl.sum('骑士冲呀官网,关注公众号');
      $(".wechat_pop").fadeIn()
      $(".share-wrap .popup_content").addClass("popup_content-ani");
    });
    $(".gzh").click(function (event) {
      __bl.sum('骑士冲呀官网,关注公众号');
      $(".wechat_pop").fadeIn()
      $(".share-wrap .popup_content").addClass("popup_content-ani");
    });
    $(".helper").click(function (event) {
      __bl.sum('骑士冲呀官网,关注企微');
      $(".qwechat_pop").fadeIn()
      $(".share-wrap .popup_content").addClass("popup_content-ani");
    });
    $('.qqq').click(function () {
      __bl.sum('骑士冲呀官网,qq群');
      window.location.href = `http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=AWgZOzAK9rtliMSlmPxD1xVQnZy0uVRS&authKey=MU8xBRynVA2JRrSZewafa8WgsfYG1PrLgW4qutOK%2FJnAmNteal7v9hqEyPRfFWfc&noverify=0&group_code=153584417`;
    })

    $('.taptap').click(function () {
      __bl.sum('骑士冲呀官网,taptap');
      window.location.href = `https://l.taptap.cn/0KCtgh3Q?channel=rep-rep_ckngqgq9gmr`;
    })

    //适龄弹窗
    $(".year_box").click(function () {
      $(".year_pop").fadeIn();
    });

    //适龄弹窗
    $(".year").click(function () {
      __bl.sum('骑士冲呀官网,适龄弹窗点击');
      $(".year_pop").fadeIn();
    });

    // 点击弹窗背景关闭弹窗
    $('.close_bg, .close_wrapper').click(function (event) {
      $('.popup, .popup_wrap').fadeOut();
    });

    // 关闭下载提示
    $('.download-wrap').on('click', function () {
      $(this).fadeOut();
    });

    $('.wx-btn').click(function (event) {
      $('.share-wrap .popup_content').addClass('popup_content-ani');
    });

    $('.close_bg').click(function (event) {
      setTimeout(function () {
        $('.share-wrap .popup_content').removeClass('popup_content-ani');
        $('.share-wrap').removeClass('gzh-wrap')
      }, 500);
    });
    $(".download-btn").click(function (event) {
      // return window.location.href = "https://h5.qingcigame.com/pre-registration/index.html";
      // 是否是移动端
      var className = $(this).attr("class");
      $.ajax({
        type: "get",
        url: "https://mapi.qingcigame.com/get_url?game_id=" + game_id + "",
        dataType: "json",
        success: function (json) {
          console.log(className);
          if (className.indexOf("tap-btn") !== -1) {
            __bl.sum('骑士冲呀官网,taptap');
            location.href = "https://l.taptap.cn/0KCtgh3Q?channel=rep-rep_ckngqgq9gmr";
            return;
          }
          if (
            /Android|webOS|iPhone|iPod|BlackBerry|Mozilla|Safari/i.test(
              navigator.userAgent
            )
          ) {
            // 判断是安卓还是苹果
            if (u.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
              // 安卓下判断是否是微信打开
              $(".download-wrap").fadeIn();
            } else {
              if (className.indexOf('appStore') !== -1) {
                if (json.data.ios_down_url == '' || !json.data.ios_down_url) {
                  layer.msg('ios下载暂未开放，敬请期待！<br/>', { time: 3000 });
                } else {
                  __bl.sum('骑士冲呀官网,ios下载');
                  location.href = json.data.ios_down_url;
                }
              } else if (className.indexOf('android') !== -1) {
                // 安卓
                if (json.data.android_download_url == '' || !json.data.ios_down_url) {
                  layer.msg('安卓下载暂未开放，敬请期待！<br/>', { time: 3000 });
                } else {
                  var type = window.location.href.split('?type=')[1];

                  var type = window.location.href.split("?type=")[1];
                  if (type) {
                    console.log(type);
                    let url = "https://download4.qcplay.com/apk/" + type + ".apk";
                    location.href = url;
                    return;
                  } else {
                    console.log("android");
                    __bl.sum('骑士冲呀官网,android下载');
                    location.href = json.data.android_down_url;
                  }

                }
              }
            }
          }
        },
      });
    });
  },

  indexAjax: function () {
    // 初始文章数据
    this.onAjax({
      url: `${api}/web/knight/index`,
      type: 'GET',
      data: {
        // limit: 5,
        game_id: game_id,
      },
      xhrFields: {
        withCredentials: true
      },
      success: function (res) {
        if (res.code == 200) {
          var data = res.data.articleList || {};
          const dataMap = {
            gong_gao: "gong_gao",
            activity_news: "activity_news"
          }

          for (let i in data) {
            let node = ''
            data[i].forEach(element => {
              let label = "";
              switch (element.cate_id) {
                case 108:
                  label = "活动";
                  break;
                case 107:
                  label = "公告";
                  break;
              }
              node += `
              <li>
                <a href="news_details.html?id=${element.id}">
                  <div class="column">
                  <span><div class="column_icon"></div>【${label}】 ${element.article_title}</span>
                  </div>
                </a>
              </li>`
            })
            $(`.${dataMap[i]}`).append(node)
          }

          let gl_zx = data.gl_zx

          let activity_news = data.activity_news
          let gong_gao = data.gong_gao

          $.each(gong_gao, function (index, values) {
            var zongheHtml = '<li>' +
              '<a href="news_details.html?id=' + values.id + '">' +
              '<div class="li_title">' + values.article_title + '</div>' +
              '<div class="li_time">' + values.created_at + '</div>' +
              '</a>' +
              '</li>';
            $('.gong_gao ul').append(zongheHtml);
          });

          $.each(activity_news, function (index, values) {
            var zongheHtml = '<li>' +
              '<a href="news_details.html?id=' + values.id + '">' +
              '<div class="li_title">' + values.article_title + '</div>' +
              '<div class="li_time">' + values.created_at + '</div>' +
              '</a>' +
              '</li>';
            $('.activity_news ul').append(zongheHtml);
          });


          $(`.swiper_img`).click(function () {
            var _this = $(this);
            var data = _this.attr('data');
            console.log(data)
            $('.overlay').fadeIn().find('img').attr('src', data);
          })
          $('.overlay').click(function () {
            $('.overlay').fadeOut()
          })

        }
      },
      error: function (json) {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
      }
    });
    // 轮播图
    this.onAjax({
      url: `${api}/web/news/info`,
      type: "GET",
      data: {
        game_id: game_id,
      },
      success: function (res) {
        if (res.code == 200) {
          $(".swiper-banner .swiper-wrapper .swiper-slide").remove();
          var data = res.data.article || {};
          console.log(data);
          const { game_information, recommend } = data;
          var list = recommend
          let bannerNode = "";
          list.forEach((item) => {
            bannerNode += `<div class="swiper-slide">
                    <a href="${item.jump_url}">
                      <div class="banner_img">
                        <img src="${item.image}" title="公告" />
                      </div>
                      <div class="bottom_box">
                        <div class="banner_title" data=${item.id} jump_url=${item.jump_url}>
                          <div>${item.name}</div>
                        </div>
                        <div class="blur_bg"></div>
                      </a>
                    </div>
                  </div> `;
          });

          // dom插入完成后 再初始化推荐板块的swiper
          $(`#swiperBanner`).append(bannerNode);

          obj.bannerSwiper();
        }
      },
      error: function (json) {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
      },
    });

  },
  // 获取报社列表
  newsBind: function () {
    // 第三方页面跳转
    $('.tab_bar ul li').click(function (event) {
      let cate_id = $(this).attr("data-id")
      if ($(this).hasClass("active")) return false
      $(this).addClass("active").siblings().removeClass("active")
      window.location.href = window.location.href.split("?")[0] + '?limit=10&cate_id=' + cate_id + '&page=1'
    });
    var current = window.location.href.split("?")[0];
    var index = layer.load(2, { shade: [0.1, '#fff'] });
    var type = "0";
    var page = this.getQueryVariable('page');
    var limit = this.getQueryVariable('limit') || 10;
    var cate_id = this.getQueryVariable('cate_id');
    $("#b" + cate_id + "").addClass("active").siblings().removeClass("active")


    if (cate_id == 107) {
      $('.address .sort_01').html('活动资讯');
      $('.address .sort_02').html('公告');
      $(".address .sort_01").attr("href", `index.html?index=1`);
      // $(".address").text("您当前的位置：官网首页>游戏资讯>公告");
      $(".main_wrap .top .title").text("公告");
      $(' .nav-link-0' + 1 + '').addClass("active").siblings().removeClass("active")
    }
    if (cate_id == 108) {
      $('.address .sort_01').html('活动资讯');
      $('.address .sort_02').html('活动');
      $(".address .sort_01").attr("href", `index.html?index=1`);
      // $(".address").text("您当前的位置：官网首页>游戏资讯>活动");
      $(".main_wrap .top .title").text("活动");
      $(' .nav-link-0' + 1 + '').addClass("active").siblings().removeClass("active")
    }
    var cate_id_str = {
      107: "公告",
      108: "活动",
    }
    this.onAjax({
      url: `${api}/web/article`,
      type: 'GET',
      data: {
        page: page,
        type: type,
        limit: limit,
        cate_id: cate_id,
        game_id: game_id,
      },
      success: function (res) {
        if (res.code == 200)
          var current_page = res.data.articleList.current_page;  // 当前页数
        var last_page = res.data.articleList.last_page;        // 总共页数
        var prev_page_url = res.data.articleList.prev_page_url;// 前一页
        var next_page_url = res.data.articleList.next_page_url;// 后一页{
        layer.close(index);
        console.log(res)
        // 翻页功能
        // obj.pageDown(last_page, next_page_url, 'id');
        var data = res.data.articleList.data || {};
        console.log(data)
        let node = ''
        data.forEach(element => {
          node += `
            <a class="content" href="news_details.html?id=${element.id}">
              <div class="content_wrap">
                <div class="content_bg">
                  <div class="article_bg">
                    <div class="title_box">
                      <div class="title">
                        <div class="tag">【${cate_id_str[element.cate_id]}】</div>
                        <text>${element.article_title}</text>
                      </div>
                      <div class="created_time">${element.created_at}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="active_bg"></div>
          </a>
          `
        })
        $(".common_list").append(node)

        $('.current_page').html(current_page);      // 当前页数
        $('.last_page').html(last_page);            // 总共页数
        if (prev_page_url === null) {                // 上一页
          $('.front-link').addClass("disabled"); // 没有第一页
          $('.front-link').attr('href', "javascript:;")
        } else {
          $('.front-link').removeClass("disabled");
          $('.front-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + prev_page_url.split("?page=")[1])
        }
        if (next_page_url === null) {                // 下一页
          $('.after-link').addClass("disabled");
          $('.after-link').attr('href', "javascript:;")
        } else {
          $('.after-link').removeClass("disabled");
          $('.after-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + next_page_url.split("?page=")[1])
        }

      },
      error: function (json) {
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
        layer.close(index);
      }
    });


  },
  // 翻页功能
  pageDown: function (lastPage, nextPage, cite) {
    var href = window.location.href.split("?")[0] + '?' + cite + '='
    console.log(href)
  },

  // 公共事件绑定
  partPublicFn: function () {
    $('.back_btn').click(function () {
      console.log(123);
      $('html,body').scrollTop(0);
      document.documentElement.scrollTop = 0; // for modern browsers
      document.body.scrollTop = 0; // for older browsers
      return false;
    });
    $('.back').click(function () {
      window.location.href = `https://qscy.qingcigame.com/official/index.html`;
    })
    $('.float_bg_btn').click(function (e) {
      var data = $(this).attr('data');
      $(this).addClass("active").siblings().removeClass("active")
      window.location.href = `https://qscy.qingcigame.com/official/index.html?index=${data}`;
    })
  },

  // 文章详情相关功能
  detailBind: function () {
    var id = this.getQueryVariable('id');
    var index = layer.load(2, { shade: [0.1, '#fff'] });
    this.onAjax({
      url: `${api}/web/article/${id}`,
      type: 'GET',
      success: function (res) {
        $('.details .main .main_wrap').addClass('main-show');
        if (res.code == 200) {
          console.log(res)
          layer.close(index);
          var data = res.data.list || {};
          const { one_level_name, cate_name, article_title, created_at, article_content, cate_id } = res.data.list || {};

          $('.main_wrap .sort_01').html(one_level_name);
          $(".main_wrap .sort_01").attr("href", `information.html?&page=1&limit=10&cate_id=${cate_id}`);
          $('.main_wrap .sort_02').html(cate_name);
          $('.news_title .title').html(article_title);
          $('.news_title .time').html(created_at);
          $('.main_wrap iframe').attr('src', 'news-iframe.html?id=' + id);


          // 获取当前ifname子页面的高度
          function iframeAutoFit(iframeObj) {
            setTimeout(() => {
              if (!iframeObj) return;
              iframeObj.height = (iframeObj.Document ? iframeObj.Document.body.scrollHeight + 20 : iframeObj.contentDocument.body.offsetHeight + 20)
            }, 500)
          }
          $('#external-frame').load(function () { //  等iframe加载完毕       
            let obj = document.getElementById('external-frame')
            iframeAutoFit(obj);
            layer.close(index);
            $("#external-frame").contents().find('.iframe').append(article_content);
          });

        } else {
          layer.close(index);
          layer.msg(res.message, { time: 2000 });

        }
      },
      error: function (json) {
        layer.close(index);
        layer.msg("服务器请求失败", { time: 2000 });
      },
      complete: function () {
        layer.close(index);
      }
    });
  },

  // 全屏滚动判断
  scroll: function () {
    $('.return-top').click(function (event) {
      document.getElementsByClassName('index')[0].scroll(0, 0)
    });
  },

  copyUrl: function () {
    $('.copy-btn').html(window.location.href)
    var clipboard = new Clipboard('.copy-btn');
    clipboard.on('success', function (e) {
      console.info('Action:', e.action);
      console.info('Text:', e.text);
      console.info('Trigger:', e.trigger);
      layer.msg('复制成功', { time: 3000 });
      e.clearSelection();
    });

    clipboard.on('error', function (e) {
      console.error('Action:', e.action);
      console.error('Trigger:', e.trigger);
    });
  },
  // 获取当前hash的具体参数
  getQueryVariable: function (variable) {
    if (window.location.href.indexOf("?") !== -1) {
      var query = window.location.href.split("?")[1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    } else {
      return null;
    }

  },
  // 简易封装常用ajax
  onAjax: function (options) {
    const { url, type, success, error, data } = options;
    $.ajax({
      url: url,
      type: type,
      data: data,
      success: function (json) {
        if (json.code !== 200) {
          var message = json.message;
          layer.msg(message);
          return false;
        }
        success && success(json);
      },
      error: function (err) {
        layer.msg(err.message);
        error && error(err);
      }
    });
  },
  newYearTime: function () {
    var date = {
      isDuringDate: function (beginDateStr, endDateStr) {
        var curDate = new Date(),
          beginDate = new Date(beginDateStr),
          endDate = new Date(endDateStr);
        if (curDate >= beginDate && curDate <= endDate) {
          $('.li-07, .li-08').removeClass('change-off');
          return true;
        }
      }
    };
    date.isDuringDate('2021/02/12', '2022/02/12');
  },
  // 微信分享
  share: function () {
    var share_icon = "https://download4.qcplay.com/qcgame-api/OHgUUn2oLuhKymJmivJinbhOTzDezC9X0X75nhYC.png"
    var share_big_title = "《骑士冲呀》官网"
    var share_min_title = "骑士冲呀"
    var share_link = window.location.href
    outside.share(share_icon, share_big_title, share_min_title, share_link)
  },
}